import React from 'react';
import getIn from 'lodash/get';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';
import styled, { ThemeProvider } from 'styled-components';
import { light, dark } from 'themes';
import breakpoints from 'utils/breakpoints';

import { HEADER_HEIGHT } from 'components/Header';
import Layout from 'components/Layout';
import { graphql } from 'gatsby';

const Heading = styled.h1`
  margin: 1.88rem 0 0.75rem 0;
  font-family: Helvetica;
  font-size: 1.625rem;
  font-weight: 700;
  letter-spacing: 0;
  color: ${props => getIn(props, 'theme.heading.textColor', '#1c2f45')};
  text-align: left;
  @media (min-width: ${breakpoints.md}) {
    margin: 2.5rem 0 2.5rem 0;
    font-size: 2.2rem;
  }
`;

const Wrapper = styled.div`
  background-image: linear-gradient(180deg, #ddeeff 0%, #ffffff 30%);
`;

const Intro = styled.section`
  padding-top: ${HEADER_HEIGHT}px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;

  & > h3 {
    margin: 1.5rem 0;
  }

  @media (min-width: ${breakpoints.md}) {
    h3 {
      text-align: left;
    }
  }
`;

const Body = styled.main`
  color: #1c2f45;
  padding-bottom: 150px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 1rem;
    font-family: Helvetica;
  }

  p {
    margin: 0 0 1rem;
    font-size: 16px;
    line-height: 24px;
  }

  li {
    line-height: 24px;
  }

  a:visited,
  a {
    color: #0276f1;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  td {
    padding: 0.5rem;
  }
`;

function PrivacyPage({ data }) {
  const content = data.markdownRemark.html;
  const { heading, title } = data.markdownRemark.frontmatter;

  return (
    <ThemeProvider theme={light}>
      <Layout fullSize>
        <Wrapper>
          <ThemeProvider theme={dark}>
            <Container>
              <Row justify="center">
                <Col xs={10} lg={8}>
                  <Intro>
                    <Heading>{heading || title}</Heading>
                  </Intro>
                  <Body dangerouslySetInnerHTML={{ __html: content }} />
                </Col>
              </Row>
            </Container>
          </ThemeProvider>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  );
}

PrivacyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        heading: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        heading
      }
      html
    }
  }
`;

export default PrivacyPage;
